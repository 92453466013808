.section {
    padding-top: 5em !important;
    padding-bottom: 3em;
}

.navbar-nav li {
    padding: 0;
}

.offer {
    padding: 1em;
}

.offer h1 {
    font-size: 2.5rem;
}

.offer p {
    font-size: 1.2rem;
}
