.ruleGroup {
    background: none;
    border: none;
    padding: 0;
}

.ruleGroup-addRule, .ruleGroup-addGroup {
    font-size: 0.875rem;
    border-radius: 0.2857rem;
    padding: 5px 15px;
    background: #1d8cf8;
    background-color: rgb(29, 140, 248);
    background-position-x: 0%;
    background-position-y: 0%;
    background-image: none;
    background-size: auto;
    background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #1d8cf8;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    border: none;
    position: relative;
    overflow: hidden;
    margin: 4px 1px;

    &:hover {
        background-position: bottom left;
        background-color: #1d8cf8;
        box-shadow: none;
    }
}

.rule-remove, .ruleGroup-remove {
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 0.2857rem;
    padding: 0px 10px;
    background: #fd5d93;
    background-color: rgb(29, 140, 248);
    background-position-x: 0%;
    background-position-y: 0%;
    background-image: none;
    background-size: auto;
    background-image: linear-gradient(to bottom left, #fd5d93, #ec250d, #fd5d93);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #fd5d93;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    border: none;
    position: relative;
    overflow: hidden;
    &:hover {
        background-position: bottom left;
        background-color: #1d8cf8;
        box-shadow: none;
    }

}

.ruleGroup-combinators, .rule-fields, .rule-operators {
    border-color: #2b3553;
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: #27293d;
    color: #fff;
    border: 1px solid #2b3553;
    padding : 0.5rem;
}

.rule-value {
    border-color: #2b3553;
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: #27293d;
    color: #fff;
    border: 1px solid #2b3553;
    padding : 0.5rem;
}

/*CUSTOM HEADER CARD */
.ks-card-header-primary {
    background-image: linear-gradient(to bottom left, #337ab7, #127edc, #337ab7);
    background-size: "210% 210%";
    background-position: "100% 0";
}

.ks-card-header-info {
    background-image: linear-gradient(to bottom left,#1d8cf8,#3358f4,#1d8cf8);
    background-size: "210% 210%";
    background-position: "100% 0";
}

.ks-card-header-success {
    background-image: linear-gradient(to bottom left,#00f2c3,#0098f0,#00f2c3);
    background-size: "210% 210%";
    background-position: "100% 0";
}

.ks-card-header-warning {
    background-image: linear-gradient(to bottom left,#ff8d72,#ff6491,#ff8d72);
    background-size: "210% 210%";
    background-position: "100% 0";
}

.ks-card-header-danger {
    background-image: linear-gradient(to bottom left,#fd5d93,#ec250d,#fd5d93);
    background-size: "210% 210%";
    background-position: "100% 0";
}

.ks-card-header-default {
    background-image: linear-gradient(to bottom left,#344675,#263148,#344675);
    background-size: "210% 210%";
    background-position: "100% 0";
}